<template>
  <div :class="['event-management-wrap', 'main', {center: visibleLoader}]">
    <ui-loader v-model="visibleLoader" />
    <div class="main__left" v-if="!visibleLoader">
      <div class="main__left-top">
        <div @click="$router.go(-1)" class="back">
          <img src="../../../assets/svg/arrows/back.svg" alt="">
          <p>{{ $t('back') }}</p>
        </div>
        <router-link :to="`/edit-event-management/${getEventsId.id}`" class="link" v-if="getUser?.role === 'Moderator' || getUser?.role === 'Admin'">{{ $t('change-event') }}</router-link>
        <router-link :to="`/event-participants/register/${getEventsId.id}`" class="link" v-if="getUser?.role === 'Moderator' || getUser?.role === 'Admin'">{{ $t('register-a-participant') }}</router-link>
      </div>
      <p class="main__left-bottom" @click="visibleAlert = true" v-if="getUser?.role === 'Moderator' || getUser?.role === 'Admin'">{{ $t('delete-event') }}</p>
    </div>
    <div class="main__right" v-if="!visibleLoader">
      <div class="main__right-header">
        <h2 class="main__right-header-title">{{ getEventsId?.name }}</h2>
        <div class="main__right-header-date">
          <p>{{ getFullDate(getEventsId.startDate)?.slice(0,10) || null }}</p>
          <p>{{ getEventsId.startTime?.slice(0, 5) || null }}</p>
        </div>
      </div>
      <div class="main__right-wrap wrap">
        <div class="wrap__image">
          <img :src="getEventsId.imageSrc?.fileSrc || require('@/assets/images/event-card.png')" alt=""/>
          <p><img src="../../../assets/svg/icon-location.svg" alt=""/>{{ getEventsId.place || $t('empty') }}</p>
          <p><img src="../../../assets/svg/icon-person-red.svg" alt=""/>{{ getEventsId.participantCnt || 0 }}</p>
          <div class="line"></div>
          <div v-if="getUser?.role !== 'Moderator' && getUser?.role !== 'Admin' && !getEventsId?.isPartTakenByMe && getUser?.role" class="wrap__image-download">
            <ui-button @click="takePart">{{ $t('participate') }}</ui-button>
          </div>
          <div v-if="getUser?.role !== 'Moderator' && getUser?.role !== 'Admin' && getEventsId?.isPartTakenByMe" class="wrap__image-download">
            <p color="white" @click="cancelPart">{{ $t('cancel-participation') }}</p>
          </div>
          <ui-button @click="$router.push('/login')" v-if="!isAuth">{{ $t('log-in') }}</ui-button>
        </div>
        <div class="wrap__description">
          <div class="wrap__description-item">
            <h2>{{ $t('event-organizer') }}:</h2>
            <p>{{ getEventsId.organizer || $t('empty')}}</p>
          </div>
          <div class="wrap__description-item">
            <h2>{{ $t('event-description') }}</h2>
            <p>{{ getEventsId.description || $t('empty')}}</p>
          </div>
          <div class="wrap__description-item">
            <h2>{{ $t('for-whom') }}</h2>
            <p>{{ getEventsId.target || $t('empty')}}</p>
          </div>
<!--          <div class="wrap__description-item">-->
<!--            <h2>{{ $t('event-partners') }}</h2>-->
<!--            <p>{{ $t('empty') }}</p>-->
<!--          </div>-->
          <div v-if="getUser?.role !== 'Moderator' && getUser?.role !== 'Admin' && !getEventsId?.isPartTakenByMe && getUser?.role" class="wrap__description-download">
            <ui-button @click="takePart">{{ $t('participate') }}</ui-button>
          </div>
          <div v-if="getUser?.role !== 'Moderator' && getUser?.role !== 'Admin' && getEventsId?.isPartTakenByMe" class="wrap__description-download">
            <p color="white" @click="cancelPart">{{ $t('cancel-participation') }}</p>
          </div>
        </div>
      </div>
    </div>
    <p class="main__bottom" v-if="getUser?.role === 'Moderator' || getUser?.role === 'Admin'" @click="visibleAlert = true">{{ $t('delete-event') }}</p>
    <ui-alert v-model="visibleAlert">
      <div class="ui-alert__wrap">
        <div class="ui-alert__title">{{ $t('do-you-really-want-to-delete-event') }}</div>
      </div>
      <div class="ui-alert__action">
        <ui-button @click="deleteItem(); closeAlert();" color="error">{{ $t('delete') }}</ui-button>
        <ui-button @click="visibleAlert = false" color="white">{{ $t('cancel-1') }}</ui-button>
      </div>
    </ui-alert>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiButton from "@/components/ui/UiButton.vue";
import {getFullDate} from "../../../utils/general";
import UiAlert from "@/components/ui/UiAlert.vue";
import UiLoader from "@/components/ui/UiLoader.vue";

export default {
  name: "AddEventManagement",
  components: {UiLoader, UiAlert, UiButton},

  data () {
    return {
      visibleAlert: false,
      visibleLoader: true
    }
  },

  methods: {
    getFullDate,
    ...mapActions(['eventsId', 'deleteEvent', 'eventTakePart', 'eventCancelPart']),
    ...mapMutations(['setEvents']),
    callDeleteApi () {
      this.deleteEvent({id: this.$route.params.id}).then(() => {
        this.setEvents([])
        this.$router.push('/event-management')
      })
    },

    closeAlert () {
      this.visibleAlert = false;
    },

    deleteItem () {
      this.callDeleteApi()
      this.closeAlert()
    },

    takePart () {
      this.visibleLoader = true
      this.eventTakePart({id: this.getEventsId.id}).then(() => {
        this.eventsId({
          id: this.$route.params.id
        }).then(() => {
          this.visibleLoader = false
        })
      })

    },

    cancelPart () {
      this.visibleLoader = true
      this.eventCancelPart({id: this.getEventsId.id}).then(() => {
        this.eventsId({
          id: this.$route.params.id
        }).then(() => {
          this.visibleLoader = false
        })
      })
    }
  },

  computed: {
    ...mapGetters(['getUser', 'getEventsId']),

    isAuth() {
      return this.$store.getters.isAuth
    }
  },

  mounted() {
    this.eventsId({
      id: this.$route.params.id
    }).then(() => {
        this.visibleLoader = false
    })
  }
}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  background: #F5F5F5;
  padding: 150px 50px 50px 50px;
  min-height: 100vh;

  &.center {
    justify-content: center;
  }

  @media (max-width: 1000px) {
    gap: 20px;
    padding: 104px 20px 50px 20px;
    flex-direction: column;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-top {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (max-width: 1000px) {
        gap: 20px;
      }

      .back {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
      }

      .link {
        font-size: 16px;
        white-space: nowrap;
      }
    }

    &-bottom {
      color: #9A9A9A;
      font-size: 16px;
      cursor: pointer;

      @media (max-width: 1000px) {
        display: none;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 20px;
    background: #FFFFFF;
    max-width: 1000px;
    width: 100%;
    padding: 40px 30px;

    @media (max-width: 768px) {
      padding: 30px 12px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media (max-width: 900px) {
        max-width: 100%;
      }

      &-title {
        color: #1B1A1F;
        font-size: 20px;
        margin-top: -5px;
      }

      &-date {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;

        p {
          color: #9A9A9A;
          font-size: 12px;
        }
      }
    }

    .wrap {
      display: flex;
      gap: 30px;
      border-top: 1px solid #F5F5F5;
      padding-top: 30px;

      @media (max-width: 900px) {
        flex-direction: column;
      }

      &__image {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 366px;
        width: 100%;

        .line {
          background-color: #F5F5F5;
          height: 1px;
          width: 100%;
        }

        &-download {
          margin-top: 10px;

          p {
            cursor: pointer;
            color: #9A9A9A;
            font-size: 16px;
            font-weight: 400;
          }

          @media (max-width: 900px) {
            display: none;
          }
        }

        img {
          max-width: 366px;
          height: 193px;
          border-radius: 10px;
          object-fit: cover;
          
          @media (max-width: 900px) {
            max-width: 100%;
          }
        }

        p {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            width: 24px;
            height: 24px;
          }

          span {
            color: #CE2121;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }

      &__description {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 544px;
        width: 100%;

        &-download {
          display: none;
          border-top: 1px solid #F5F5F5;
          padding-top: 30px;

          p {
            cursor: pointer;
            color: #9A9A9A;
            font-size: 16px;
            font-weight: 400;
          }

          @media (max-width: 900px) {
            display: inline-block;
          }
        }

        &-item {
          display: flex;
          flex-direction: column;
          gap: 10px;

          h2 {
            color: #1B1A1F;
            font-size: 16px;
          }

          p {
            color: #1B1A1F;
            font-size: 16px;
            line-height: 120.2%;
            font-family: 'Gotham Pro Regular';
            font-weight: 400;
          }
        }
      }
    }
  }
  &__bottom {
    display: none;

    @media (max-width: 1000px) {
      display: inline-block;
      color: #9A9A9A;
      font-size: 16px;
      width: 100%;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
